import React from 'react';

// Externals
import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material';

const Ice: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    color="action"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <path d="M316.28 19.063L211.19 47.25l36.625 67.78l-61.25 16.44L184 54.53L80.437 82.313L52.47 186.72l77 2.186L113 250.344l-67.875-36.22L17.22 318.19l75.843 75.843l40.593-65.56l45.25 45.25l-65.562 40.624l76.53 76.53l103.782-27.812l-36.5-68.03l61.53-16.47l2.377 77.157l104.687-28.064l27.97-104.437l-77.158-2.376L393 259.47l68.03 36.5l27.908-104.22l-77-77.03l-40.47 65.718l-44.624-44.657l65.406-40.75l-75.97-75.968zM253.19 125l11.53 21.344l-22.874 69.72l-54.094-49.095l-.813-24.19l66.25-17.78zm64.062 16.75l48.28 48.313l-13 21.125l-71.81 15.062l15.467-71.375l21.063-13.125zm-176.438 47.47l23.75.686l48.938 54.656l-69.563 22.282L123 255.656l17.813-66.437zm221.094 53.56l21.125 11.345l-17.78 66.375l-23.97-.75l-48.5-53.938l69.126-23.03zm-139.25 41.5l-14.625 71.376l-19.53 12.094l-48.875-48.906l12.094-19.5l70.936-15.063zm40.438 11.064l54.5 48.344l.72 23.53l-66.533 17.844l-11.124-20.75l22.438-68.968z" />
  </SvgIcon>
);

export default Ice;
