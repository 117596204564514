// Icons
import Fire from './icons/Fire';
import Forest from './icons/Forest';
import Ice from './icons/Ice';
import Stone from './icons/Stone';
import Toxic from './icons/Toxic';
import Water from './icons/Water';

// Models
import { Revomon } from './models/revomon.model';

// Types
import { RevomonType } from './types/revomon.type';

export const BACKGROUNDS: string[] = [
  '/images/wallpapers/jungle_background.jpg',
  '/images/wallpapers/cave_background.jpg',
  '/images/wallpapers/tundra_background.jpg'
];

export const FOREGROUNDS: string[] = [
  '/images/wallpapers/jungle_foreground.png',
  '/images/wallpapers/cave_foreground.png',
  '/images/wallpapers/tundra_foreground.png'
];

export const LOGO: string = '/images/revomon.png';

export const MUSIC: string[] = ['/music/jungle_biome.mp3', '/music/cave_biome.mp3', '/music/tundra_biome.mp3'];

export const REVOMON: {
  [Key in RevomonType]: Revomon;
} = {
  DEKUTE: {
    description: "Dekute's are usually very timid but also like to be sneaky and playful.",
    name: 'Dekute',
    type1: {
      icon: Toxic({ sx: { fontSize: 10 } }),
      name: 'Toxic'
    },
    type2: {
      icon: Forest({ sx: { fontSize: 10 } }),
      name: 'Forest'
    },
    atk: 49,
    def: 49,
    hp: 45,
    basic: { color1: 'goldenrod', color2: 'forestgreen' },
    shiny: { color1: 'mistyrose', color2: 'sandybrown' }
  },
  DESUKE: {
    description:
      'Desukes allow their sneaky nature to influence them to learn more ambushing techniques. This has unlocked a dark power inside them.',
    name: 'Desuke',
    type1: {
      icon: Toxic({ sx: { fontSize: 10 } }),
      name: 'Toxic'
    },
    type2: {
      icon: Forest({ sx: { fontSize: 10 } }),
      name: 'Forest'
    },
    atk: 62,
    def: 63,
    hp: 60,
    basic: { color1: 'goldenrod', color2: 'forestgreen' },
    shiny: { color1: 'mistyrose', color2: 'sandybrown' }
  },
  DEKSCIPLE: {
    description:
      'This revomon is a swift assassin. Empowered by a mysterious forest energy, it can move through the shadows to stalk its preys.',
    name: 'Deksciple',
    type1: {
      icon: Toxic({ sx: { fontSize: 10 } }),
      name: 'Toxic'
    },
    type2: {
      icon: Forest({ sx: { fontSize: 10 } }),
      name: 'Forest'
    },
    atk: 82,
    def: 83,
    hp: 80,
    basic: { color1: 'goldenrod', color2: 'forestgreen' },
    shiny: { color1: 'mistyrose', color2: 'sandybrown' }
  },
  GORLIT: {
    description:
      'They like to race other down the volcanos by rolling. Their skin on their backs is as hard as stone. They are playful but can be very territorial.',
    name: 'Gorlit',
    type1: {
      icon: Fire({ sx: { fontSize: 10 } }),
      name: 'Fire'
    },
    type2: undefined,
    atk: 52,
    def: 43,
    hp: 39,
    basic: { color2: 'darkgoldenrod', color1: 'saddlebrown' },
    shiny: { color2: 'darkgoldenrod', color1: 'grey' }
  },
  GOROX: {
    description:
      'Goroxs are known for their eagerness to challenge others on the top of Volcanos to spar. They like to eat molten rocks to fuel their strenght.',
    name: 'Gorox',
    type1: {
      icon: Fire({ sx: { fontSize: 10 } }),
      name: 'Fire'
    },
    type2: undefined,
    atk: 64,
    def: 58,
    hp: 58,
    basic: { color2: 'darkgoldenrod', color1: 'saddlebrown' },
    shiny: { color2: 'darkgoldenrod', color1: 'grey' }
  },
  GORCANO: {
    description:
      'Covering themselves with a volcano, they are known as the guardians of the mountain. They are able to erupt.',
    name: 'Gorcano',
    type1: {
      icon: Fire({ sx: { fontSize: 10 } }),
      name: 'Fire'
    },
    type2: {
      icon: Stone({ sx: { fontSize: 10 } }),
      name: 'Stone'
    },
    atk: 84,
    def: 78,
    hp: 78,
    basic: { color2: 'darkgoldenrod', color1: 'saddlebrown' },
    shiny: { color2: 'darkgoldenrod', color1: 'grey' }
  },
  ZORELLE: {
    description:
      'They are extremely positive and always want to show off to their swimming prowess to their trainer. Despite their love for swimming, they hate cold water.',
    name: 'Zorelle',
    type1: {
      icon: Water({ sx: { fontSize: 10 } }),
      name: 'Water'
    },
    type2: undefined,
    atk: 48,
    def: 65,
    hp: 44,
    basic: { color1: 'lightblue', color2: 'violet' },
    shiny: { color1: 'pink', color2: 'white' }
  },
  ZORENE: {
    description:
      'Despite hating the cold, they have adapted the ability to swim through frozen waters. The ice often sticks to them, so they wear it as accolades.',
    name: 'Zorene',
    type1: {
      icon: Water({ sx: { fontSize: 10 } }),
      name: 'Water'
    },
    type2: undefined,
    atk: 63,
    def: 80,
    hp: 59,
    basic: { color1: 'lightblue', color2: 'violet' },
    shiny: { color1: 'pink', color2: 'white' }
  },
  BLIZZORA: {
    description:
      'They can overcome swimming through any body of water. They use the water, which was once their weakness, as a weapon.',
    name: 'Blizzora',
    type1: {
      icon: Water({ sx: { fontSize: 10 } }),
      name: 'Water'
    },
    type2: {
      icon: Ice({ sx: { fontSize: 10 } }),
      name: 'Ice'
    },
    atk: 83,
    def: 100,
    hp: 79,
    basic: { color1: 'lightblue', color2: 'violet' },
    shiny: { color1: 'pink', color2: 'white' }
  }
};

export const STARTERS: RevomonType[] = ['DEKUTE', 'GORLIT', 'ZORELLE'];
