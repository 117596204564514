import React, { useCallback, useLayoutEffect } from 'react';

// Externals
import { useTheme } from '@mui/material';
import { animated, useTransition } from '@react-spring/web';
import PropTypes from 'prop-types';
import AnimatedCursor from 'react-animated-cursor';

// Screens
import SplashScreen from './screens/splashscreen';

import { metaMask, hooks as metaMaskHooks } from './connectors/metaMask';
import { trustWallet, hooks as trustWalletHooks } from './connectors/trustWalet';
import { hooks as walletConnectHooks, walletConnectV2 } from './connectors/walletConnectV2';
import { MetaMask } from '@web3-react/metamask';
import { TrustWallet } from '@trustwallet/web3-react-trust-wallet';
import { Web3ReactHooks, Web3ReactProvider } from '@web3-react/core';
import Toast from './components/Toast';
import { WalletConnect } from '@web3-react/walletconnect-v2';

// suppress useLayoutEffect warnings when running outside a browser
if (typeof window === 'undefined') React.useLayoutEffect = React.useEffect;

const connectors: [MetaMask | TrustWallet | WalletConnect, Web3ReactHooks][] = [
  [metaMask, metaMaskHooks],
  [trustWallet, trustWalletHooks],
  [walletConnectV2, walletConnectHooks]
];

interface AppProps {
  children: React.ReactNode;
}

const App: React.FC<AppProps> = ({ children }) => {
  const theme = useTheme();

  const [loading, setLoading] = React.useState(true);

  const transitionSplashscreen = useTransition(loading, {
    from: { transform: 'translateY(0%)' },
    enter: { transform: 'translateY(0%)' },
    leave: { transform: 'translateY(-100%)' },
    onRest: () => {
      // Show body scrollbar
      document.body.style.cursor = 'auto';
      document.body.style.overflow = 'auto';
    }
  });

  const onReady = useCallback((): void => {
    setLoading(false);
  }, []);

  useLayoutEffect(() => {
    // Hide body scrollbar
    document.body.style.cursor = 'none';
    document.body.style.overflow = 'hidden';
  }, []);

  return (
    <React.Fragment>
      {!loading && (
        <AnimatedCursor
          clickables={[
            'a',
            'input',
            'label[for]',
            'select',
            'textarea',
            'button',
            '.MuiButtonBase-root',
            '.MuiFormControl-root'
          ]}
          innerScale={1}
          innerSize={6}
          innerStyle={{
            backgroundColor: '#ffffff',
            zIndex: 9999
          }}
          outerAlpha={0}
          outerScale={2}
          outerSize={36}
          outerStyle={{
            border: '1px solid #ffffff',
            zIndex: 9999
          }}
          trailingSpeed={8}
        />
      )}
      {transitionSplashscreen(
        (style, item) =>
          item && (
            <animated.div
              style={{
                alignItems: 'center',
                background: theme.palette.background.default,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                position: 'fixed',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                height: '100vh',
                width: '100vw',
                zIndex: 2000,
                ...style
              }}
            >
              <SplashScreen onReady={onReady} />
            </animated.div>
          )
      )}
      <Web3ReactProvider connectors={connectors}>
        <Toast />
        {children}
      </Web3ReactProvider>
    </React.Fragment>
  );
};

App.propTypes = {
  children: PropTypes.node
};

export default App;
