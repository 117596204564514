exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lands-tsx": () => import("./../../../src/pages/lands.tsx" /* webpackChunkName: "component---src-pages-lands-tsx" */),
  "component---src-pages-sections-ecosystem-tsx": () => import("./../../../src/pages/sections/ecosystem.tsx" /* webpackChunkName: "component---src-pages-sections-ecosystem-tsx" */),
  "component---src-pages-sections-home-tsx": () => import("./../../../src/pages/sections/home.tsx" /* webpackChunkName: "component---src-pages-sections-home-tsx" */),
  "component---src-pages-sections-lands-landpaper-tsx": () => import("./../../../src/pages/sections/lands/landpaper.tsx" /* webpackChunkName: "component---src-pages-sections-lands-landpaper-tsx" */),
  "component---src-pages-sections-lands-map-tsx": () => import("./../../../src/pages/sections/lands/map.tsx" /* webpackChunkName: "component---src-pages-sections-lands-map-tsx" */),
  "component---src-pages-sections-lands-perks-tsx": () => import("./../../../src/pages/sections/lands/perks.tsx" /* webpackChunkName: "component---src-pages-sections-lands-perks-tsx" */),
  "component---src-pages-sections-lands-sales-tsx": () => import("./../../../src/pages/sections/lands/sales.tsx" /* webpackChunkName: "component---src-pages-sections-lands-sales-tsx" */),
  "component---src-pages-sections-lands-trailer-tsx": () => import("./../../../src/pages/sections/lands/trailer.tsx" /* webpackChunkName: "component---src-pages-sections-lands-trailer-tsx" */),
  "component---src-pages-sections-nft-tsx": () => import("./../../../src/pages/sections/nft.tsx" /* webpackChunkName: "component---src-pages-sections-nft-tsx" */),
  "component---src-pages-sections-novus-tsx": () => import("./../../../src/pages/sections/novus.tsx" /* webpackChunkName: "component---src-pages-sections-novus-tsx" */),
  "component---src-pages-sections-partners-tsx": () => import("./../../../src/pages/sections/partners.tsx" /* webpackChunkName: "component---src-pages-sections-partners-tsx" */),
  "component---src-pages-sections-play-and-earn-tsx": () => import("./../../../src/pages/sections/play_and_earn.tsx" /* webpackChunkName: "component---src-pages-sections-play-and-earn-tsx" */),
  "component---src-pages-sections-team-tsx": () => import("./../../../src/pages/sections/team.tsx" /* webpackChunkName: "component---src-pages-sections-team-tsx" */),
  "component---src-pages-styleguide-tsx": () => import("./../../../src/pages/styleguide.tsx" /* webpackChunkName: "component---src-pages-styleguide-tsx" */)
}

