import React from 'react';

// Externals
import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material';

const Stone: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    color="secondary"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <path d="m317.727 108.904l-95.192 96.592l-26.93 86.815l17.54 36.723l20.417 9.287l33.182-55.082l11.297-3.61l61.75 26.85l20.26-12.998l4.47-43.7l11.42 53.634l-10.622 14.162l3.772 1.64l5.238 6.5l6.832 34.343l55.977-66.775l13.98.23l22.397 28.575l-9.453-52.244L434.01 166.81l-116.28-57.906zM123.61 120.896L94.08 173l-4.603 27.62l25.98-8.442l11.704 7.377l.084.634l28.295 59.865l13.773-4.543l10.94 4.668l3.922 8.21l19.517-62.917l-1.074-33.336l-40.15-.522l-29.732-23.78l34.06 10.888l42.49-7.727l26.034 15.88l36.282-36.815a332.698 332.698 0 0 0-8.58-3.52l-79.58 10.126l-3.528-.25l-56.307-15.52zm249.33 36.422l47.058 66.02l2.107 62.51l-25.283-59.698l-65.322-60.404l41.44-8.428zm-262.2 55.32l-64.234 20.876l-16.71 78.552l50.794 5.582l.596-7.14l37.662-36.707l-8.108-61.16zm56.688 62.45l-36.44 12.016l-31.644 30.84l22.588 30.867l57.326 1.74l16.5-16.16l-28.33-59.302zm110.666 24.19l-44.307 73.546l-.033 57.14l97.264 12.216l44.242-19.528l-17.666-88.806l-79.5-34.567zM443.8 313.36l-46.843 55.876l.287 1.774l65.147 13.887l25.78-14.926l-44.37-56.613zm-138.382 15.89l39.23 22.842l13.41 50.658l-26.82 23.838l-45.015-2.553l38.562-28.242l2.483-39.23l-21.85-27.312zm-238.37 53.838l-8.77 28.51l13.152 48.498l91.037-11.91l1.32-26.418l-62.582-31.995l-34.156-6.684z" />
  </SvgIcon>
);

export default Stone;
