import type { AddEthereumChainParameter } from '@web3-react/types';

const ETH: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Ether',
  symbol: 'ETH',
  decimals: 18
};

const BNB: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Binance Coin',
  symbol: 'BNB',
  decimals: 18
};

interface BasicChainInformation {
  urls: string[];
  name: string;
}

interface ExtendedChainInformation extends BasicChainInformation {
  nativeCurrency: AddEthereumChainParameter['nativeCurrency'];
  blockExplorerUrls: AddEthereumChainParameter['blockExplorerUrls'];
}

function isExtendedChainInformation(
  chainInformation: BasicChainInformation | ExtendedChainInformation
): chainInformation is ExtendedChainInformation {
  return !!(chainInformation as ExtendedChainInformation)?.nativeCurrency;
}

export function getAddChainParameters(chainId: number): AddEthereumChainParameter | number {
  const chainInformation = CHAINS[chainId];
  if (isExtendedChainInformation(chainInformation)) {
    return {
      chainId,
      chainName: chainInformation.name,
      nativeCurrency: chainInformation.nativeCurrency,
      rpcUrls: chainInformation.urls,
      blockExplorerUrls: chainInformation.blockExplorerUrls
    };
  } else {
    return chainId;
  }
}

type ChainConfig = { [chainId: number]: BasicChainInformation | ExtendedChainInformation };

export const MAINNET_CHAINS: ChainConfig = {
  1: {
    urls: ['https://ethereum-rpc.publicnode.com'],
    name: 'Ethereum',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://etherscan.io']
  },
  56: {
    urls: ['https://bsc-dataseed.binance.org:443/'],
    name: 'Binance Smart Chain',
    nativeCurrency: BNB,
    blockExplorerUrls: ['https://bscscan.com']
  }
};

export const TESTNET_CHAINS: ChainConfig = {
  11155111: {
    urls: ['https://sepolia.drpc.org'],
    name: 'Sepolia Testnet',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://sepolia.etherscan.io']
  },
  97: {
    urls: ['https://bsc-testnet.drpc.org'],
    name: 'BNB Testnet',
    nativeCurrency: BNB,
    blockExplorerUrls: ['https://testnet.bscscan.com']
  }
};

export const CHAINS: ChainConfig = {
  ...MAINNET_CHAINS,
  ...TESTNET_CHAINS
};

export const URLS: { [chainId: number]: string[] } = Object.keys(CHAINS).reduce<{ [chainId: number]: string[] }>(
  (accumulator, chainId) => {
    const validURLs: string[] = CHAINS[Number(chainId)].urls;

    if (validURLs.length) {
      accumulator[Number(chainId)] = validURLs;
    }

    return accumulator;
  },
  {}
);
