import React, { forwardRef, useEffect, useLayoutEffect, useState } from 'react';

// Components
import CardProgress from '../components/CardProgress';

// Constants
import * as constants from '../constants';

// Externals
import { Box } from '@mui/material';
import { useProgress } from '@react-three/drei';
import { animated, useChain, useSpring, useSpringRef } from '@react-spring/web';
import { useImage } from 'react-image';
import { useInterval } from 'react-use';

interface SplashScreenProps {
  onReady: () => void;
}

const SplashScreen = forwardRef<HTMLDivElement, SplashScreenProps>(({ onReady }, ref) => {
  const { progress, loaded, total } = useProgress();

  const [percentage, setPercentage] = useState(0);

  const { src: logoImage, isLoading: logoImageLoading } = useImage({
    srcList: constants.LOGO,
    useSuspense: false
  });

  const logoFadeSpringRef = useSpringRef();
  const logoFadeSpringProps = useSpring({
    config: { duration: 500 },
    delay: 50,
    from: { opacity: 0 },
    to: { opacity: 1 },
    pause: logoImageLoading,
    ref: logoFadeSpringRef
  });

  const progressFadeSpringRef = useSpringRef();
  const progressFadeSpringProps = useSpring({
    config: { duration: 500 },
    from: { opacity: 0 },
    to: { opacity: 1 },
    pause: logoImageLoading,
    ref: progressFadeSpringRef
  });

  const progressBorderSpringRef = useSpringRef();
  const progressBorderSpringProps = useSpring({
    from: {
      borderLeft: '0px solid white',
      borderRight: '0px solid white',
      borderBottom: '1px solid transparent',
      borderTop: '1px solid transparent'
    },
    ref: progressBorderSpringRef
  });

  const progressTransformSpringRef = useSpringRef();
  const progressTransformSpringProps = useSpring({
    from: { x: 0 },
    ref: progressTransformSpringRef
  });

  const progressWidthSpringRef = useSpringRef();
  const progressWidthSpringProps = useSpring({
    from: { width: 0 },
    ref: progressWidthSpringRef
  });

  useChain([logoFadeSpringRef, progressFadeSpringRef]);

  useEffect(() => {
    if (percentage === 100) setTimeout(onReady, 500);
  }, [onReady, percentage]);

  // Animate progress bar
  useLayoutEffect(() => {
    progressBorderSpringRef.start({
      borderLeft: '0px solid white',
      borderRight: `${percentage * 3}px solid white`,
      borderBottom: '1px solid transparent',
      borderTop: '1px solid transparent'
    });
    progressTransformSpringRef.start({ x: percentage * 3 });
    progressWidthSpringRef.start({ width: percentage * 3 + 4 });
  }, [percentage, progressBorderSpringRef, progressTransformSpringRef, progressWidthSpringRef]);

  // Loading three.js will take 100% of actual progress exepct if animation is not finished
  useLayoutEffect(() => {
    if (progressFadeSpringProps.opacity.hasAnimated) setPercentage(progress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  // Simulate the rest of the actual progress
  useInterval(() => {
    // If loaded and total are equal to 0, it means three.js is already loaded
    if ((progressFadeSpringProps.opacity.hasAnimated && progress === 100 && percentage < 100) || loaded === total) {
      const random = Math.floor(Math.random() * (10 - 1) + 1);
      const value = Math.min(percentage + random, 100);

      setPercentage(value);
    }
  }, 200);

  return (
    <>
      <animated.div
        style={{
          backgroundImage: `url(${logoImage})`,
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          height: '128px',
          width: '128px',
          // Force hardware acceleration
          transform: 'translate3d(0, 0, 0)',
          willChange: 'opacity',
          ...logoFadeSpringProps
        }}
      />
      <Box
        component="div"
        sx={{
          mt: 4,
          width: 300
        }}
      >
        <animated.div
          style={{
            // Force hardware acceleration
            transform: 'translate3d(0, 0, 0)',
            willChange: 'opacity',
            ...progressFadeSpringProps
          }}
        >
          <animated.div
            style={{
              // No need to force hardware acceleration
              willChange: 'transform',
              ...progressTransformSpringProps
            }}
          >
            <CardProgress
              label={`${percentage}%`}
              sx={{
                mb: 1,
                transform: `translateX(-50%)`
              }}
            />
          </animated.div>
          <animated.div
            style={{
              position: 'relative',
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
              borderRadius: '1px',
              height: '2px'
            }}
          >
            <animated.div
              style={{
                position: 'absolute',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
                background: 'white',
                filter: 'blur(4px)',
                height: '2px',
                zIndex: 0,
                // Force hardware acceleration
                transform: 'translate3d(-2px, 0, 0)',
                willChange: 'width',
                ...progressWidthSpringProps
              }}
            />
            <animated.div
              style={{
                position: 'absolute',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
                borderRadius: '8px',
                height: '4px',
                width: '0px',
                zIndex: 1,
                // Force hardware acceleration
                transform: 'translate3d(0, -1px, 0)',
                willChange: 'border-left, border-right, border-bottom, border-top',
                ...progressBorderSpringProps
              }}
            />
          </animated.div>
        </animated.div>
      </Box>
    </>
  );
});

export default SplashScreen;
