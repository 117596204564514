import React from 'react';

// Externals
import { CacheProvider } from '@emotion/react';

// Utils
import getEmotionCache from './getEmotionCache';

const cache = getEmotionCache();

export const rootWrapper = ({ element }: { element: React.ReactNode }) => (
  <CacheProvider value={cache}>{element}</CacheProvider>
);
