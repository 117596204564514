import React from 'react';

// Externals
import { Card, Typography } from '@mui/material';
import type { Theme } from '@mui/material';
import type { SxProps } from '@mui/system';

interface CardProgressProps {
  label: string;
  sx?: SxProps<Theme>;
}

const CardProgress: React.FC<CardProgressProps> = ({ label, sx }) => {
  return (
    <Card
      sx={{
        alignItems: 'center',
        backdropFilter: 'unset',
        background: 'unset',
        border: 'unset',
        borderImageSlice: '10 23 15 23 fill',
        borderImageSource: 'url(/resources/card_progress.png)',
        borderImageWidth: 'auto',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        pt: 1,
        pb: 1.5,
        height: 35,
        width: 40,
        ...sx
      }}
    >
      <Typography color="text.secondary" variant="loader">
        {label}
      </Typography>
    </Card>
  );
};

export default CardProgress;
