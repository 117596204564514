import React from 'react';

// Externals
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ParallaxProvider } from 'react-scroll-parallax';

// Theme
import { createCustomTheme } from '../../src/theme';

export const rootWrapper = ({ element }: { element: React.ReactNode }) => {
  return (
    <ThemeProvider theme={createCustomTheme()}>
      <CssBaseline />
      <ParallaxProvider>{element}</ParallaxProvider>
    </ThemeProvider>
  );
};
