import React from 'react';

// Externals
import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material';

const Forest: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    color="success"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <path d="M462.125 21.656c-90.8 62.46-285.757-3.604-362.938 59.75c-75.358 61.862-78.86 205.444 14.75 240.625l1.72-1.874c37.05-27.143 87.683-34.04 132.937-21.47c10.06-53.15-25.31-116.6-106.75-121.03c41.182-12.678 78.062-6.115 107.094 11.5c28.17-18.51 65.59-22.273 98.5-11.844c13.642-24.687 5.494-62.023-34.875-75.062c11.547-.47 21.786 1.543 30.625 5.375c9.765-9.273 23.542-14.436 37.406-14.594c20.555-.233 41.345 10.51 49.72 35c-20.84-25.228-50.562-20.836-65.158-4.374c16.456 18.683 21.54 46.205 13.156 68.78c24.248 16.935 41.845 44.085 44.22 81.126c-28.71-63.122-92.417-69.913-131.313-43.625c20.415 30.55 28.802 68.684 20.436 103.594c24.437 22.882 41.334 55.248 44 96.845c-39.337-86.492-127.312-94.716-179.625-57.406l-.968-.126C284.356 567.204 577.93 466.81 462.125 21.656zM311 189.656c-21.31.028-42.32 7.18-61.656 20.75c11.706 22.114 34.073 72.085 5.937 111.813c-61.534-22.165-135.748 9.04-174.78 63.905c-.016.015-.046.016-.063.03c-21.582 20.457-40.824 44.172-59.406 69c8.21 23.188 26.263 36.433 58.782 35.095c14.816-43.566 33.983-80.886 59.126-112.47C183.548 320.235 251.394 332.02 282 340c12.164-38.336 12.068-84.158-12.97-113.688c24.283-25.166 67.41-33.486 98.19-19.625c-18.384-11.557-37.418-17.055-56.22-17.03z" />
  </SvgIcon>
);

export default Forest;
