import type { FC } from 'react';

// Externals
import { Alert, Fade } from '@mui/material';
import type { AlertColor } from '@mui/material';
import toast, { resolveValue, Toaster } from 'react-hot-toast';
import type { ToastType } from 'react-hot-toast';
import React from 'react';

const Toast: FC = () => {
  const castToastTypeToAlertColor = (type: ToastType): AlertColor => {
    switch (type) {
      case 'blank':
        return 'info';
      case 'error':
        return 'error';
      case 'success':
        return 'success';
      default:
        return 'info';
    }
  };

  return (
    <Toaster
      children={(t) => (
        <Fade in={t.visible}>
          <Alert
            onClose={() => {
              toast.dismiss(t.id);
            }}
            severity={castToastTypeToAlertColor(t.type)}
            sx={{ fontFamily: 'Montserrat, sans serif', mt: 1, minWidth: 300, maxWidth: 500, whiteSpace: 'pre-line' }}
            variant="filled"
          >
            {resolveValue(t.message, t)}
          </Alert>
        </Fade>
      )}
      position="bottom-center"
      toastOptions={{
        duration: 10000
      }}
    />
  );
};

export default Toast;
