import React from 'react';

// Externals
import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material';

const Fire: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    color="warning"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <path d="M162.22 21.312c-183.876 106.68 51.994 227.35-10.19 332.47C116.95 413.083 38.11 325.45 43.75 227C-6.035 353.376 30.21 443.745 95.22 492.75c1.386.34 17.577.498 41.186.562c-28.478-16.9-32.06-75.355 32.813-103.25l15.78-7L182.062 400c-1.356 8.34-.318 13.95 1.188 16.937c1.507 2.987 3.106 3.544 5.844 4.094c5.475 1.1 16.963-2.395 26.28-14.624c18.636-24.457 29.117-80.228-26.874-167l-4.094-6.437l-13.906-19.25l23.25 9.905l5.25 1.75c80.85 25.498 135.3 58.46 174.625 112.72c21.544-22.906 38.7-56.835 43.812-91.69l3.5-27.468l13.438 23.97c25.525 45.802 30.267 104.912 11.094 156.5c-14.23 38.28-42.036 72.34-84 93.5c13.623-.01 24.05.032 25.25.124c154.76-34.77 139.345-244.952-14.19-394.093c.012.726 0 1.46 0 2.188c78.964 185.87-136.465 189.104-119.25 11.47c-41.686 100.463-132.86-2.147-91.06-91.283zM269.093 332.25c13.51 15.806 22.35 33.086 22.78 50.812c.587 24.03-9.293 47.12-24.53 66.563c-14.832 18.926-34.837 34.62-56.625 43.656c33.127-.065 64.926-.16 95.217-.25l-.062-.186c30.95-18.584 47.31-38.83 53.75-57.813c6.44-18.982 3.25-37.246-5.844-53.718c-15.44-27.97-49.705-47.71-84.686-49.062z" />
  </SvgIcon>
);

export default Fire;
