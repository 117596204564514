// Externals
import { ExpandMore } from '@mui/icons-material';
import {
  accordionClasses,
  accordionSummaryClasses,
  buttonClasses,
  createTheme,
  gridClasses,
  linkClasses,
  listItemButtonClasses,
  menuItemClasses,
  outlinedInputClasses,
  responsiveFontSizes
} from '@mui/material';
import type { Shadows, Theme, ThemeOptions } from '@mui/material';
import merge from 'lodash/merge';

// Overrides
declare module '@mui/material/styles' {
  interface TypographyVariants {
    h7: React.CSSProperties;
    h8: React.CSSProperties;
    subtitleSemibold16: React.CSSProperties;
    subtitleSemibold14: React.CSSProperties;
    subtitleMedium12: React.CSSProperties;
    subtitleMedium10: React.CSSProperties;
    subtitleRegular12: React.CSSProperties;
    subtitleRegular10: React.CSSProperties;
    subtitleRegular8: React.CSSProperties;
    bodyRegular16: React.CSSProperties;
    bodyRegular14: React.CSSProperties;
    bodyRegular8: React.CSSProperties;
    linkHeader: React.CSSProperties;
    linkFooter: React.CSSProperties;
    linkMobile: React.CSSProperties;
    loader: React.CSSProperties;
    platform: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    h7?: React.CSSProperties;
    h8?: React.CSSProperties;
    subtitleSemibold16?: React.CSSProperties;
    subtitleSemibold14?: React.CSSProperties;
    subtitleMedium12?: React.CSSProperties;
    subtitleMedium10?: React.CSSProperties;
    subtitleRegular12?: React.CSSProperties;
    subtitleRegular10?: React.CSSProperties;
    subtitleRegular8?: React.CSSProperties;
    bodyRegular16?: React.CSSProperties;
    bodyRegular14?: React.CSSProperties;
    bodyRegular8?: React.CSSProperties;
    linkHeader?: React.CSSProperties;
    linkFooter?: React.CSSProperties;
    linkMobile?: React.CSSProperties;
    loader?: React.CSSProperties;
    platform?: React.CSSProperties;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    text: false;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h7: true;
    h8: true;
    subtitleSemibold16: true;
    subtitleSemibold14: true;
    subtitleMedium12: true;
    subtitleMedium10: true;
    subtitleRegular12: true;
    subtitleRegular10: true;
    subtitleRegular8: true;
    bodyRegular16: true;
    bodyRegular14: true;
    bodyRegular8: true;
    linkHeader: true;
    linkFooter: true;
    linkMobile: true;
    loader: true;
    platform: true;
    subtitle1: false;
    subtitle2: false;
    body1: false;
    body2: false;
    caption: false;
    overline: false;
  }
}

declare module '@mui/material/styles' {
  interface TypeText {
    primary: string;
    secondary: string;
    tertiary: string;
    disabled: string;
  }
}

const baseOptions: ThemeOptions = {
  direction: 'ltr',
  shape: {
    borderRadius: 8
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
      fontFamily: 'Bebas Neue, sans serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 100,
      lineHeight: 1,
      letterSpacing: 'normal',
      textTransform: 'none'
    },
    h2: {
      fontFamily: 'Bebas Neue, sans serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 60,
      lineHeight: 1,
      letterSpacing: 'normal',
      textTransform: 'none'
    },
    h3: {
      fontFamily: 'Bebas Neue, sans serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 54,
      lineHeight: 1,
      letterSpacing: 'normal',
      textTransform: 'none'
    },
    h4: {
      fontFamily: 'Bebas Neue, sans serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 32,
      lineHeight: 1,
      letterSpacing: 'normal',
      textTransform: 'none'
    },
    h5: {
      fontFamily: 'Bebas Neue, sans serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 28,
      lineHeight: 1,
      letterSpacing: 'normal',
      textTransform: 'none'
    },
    h6: {
      fontFamily: 'Bebas Neue, sans serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 22,
      lineHeight: 1,
      letterSpacing: 'normal',
      textTransform: 'none'
    },
    h7: {
      fontFamily: 'Bebas Neue, sans serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 20,
      lineHeight: 1,
      letterSpacing: 'normal',
      textTransform: 'none'
    },
    h8: {
      fontFamily: 'Bebas Neue, sans serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 1,
      letterSpacing: 'normal',
      textTransform: 'none'
    },
    subtitleSemibold16: {
      fontFamily: 'Montserrat, sans serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: 1,
      letterSpacing: -0.5,
      textTransform: 'uppercase'
    },
    subtitleSemibold14: {
      fontFamily: 'Montserrat, sans serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: 1,
      letterSpacing: -0.5,
      textTransform: 'uppercase'
    },
    subtitleMedium12: {
      fontFamily: 'Montserrat, sans serif',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 12,
      lineHeight: 1,
      letterSpacing: -0.33,
      textTransform: 'none'
    },
    subtitleMedium10: {
      fontFamily: 'Montserrat, sans serif',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 10,
      lineHeight: 1,
      letterSpacing: -0.33,
      textTransform: 'none'
    },
    subtitleRegular12: {
      fontFamily: 'Montserrat, sans serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 1,
      letterSpacing: -0.33,
      textTransform: 'none'
    },
    subtitleRegular10: {
      fontFamily: 'Montserrat, sans serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 10,
      lineHeight: 1,
      letterSpacing: -0.33,
      textTransform: 'none'
    },
    subtitleRegular8: {
      fontFamily: 'Montserrat, sans serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 8,
      lineHeight: 1,
      letterSpacing: -0.33,
      textTransform: 'none'
    },
    bodyRegular16: {
      fontFamily: 'Montserrat, sans serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 1.5,
      letterSpacing: 'normal',
      textTransform: 'none'
    },
    bodyRegular14: {
      fontFamily: 'Montserrat, sans serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 1.5,
      letterSpacing: 'normal',
      textTransform: 'none'
    },
    bodyRegular8: {
      fontFamily: 'Montserrat, sans serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 8,
      lineHeight: 1.5,
      letterSpacing: 'normal',
      textTransform: 'none'
    },
    linkHeader: {
      fontFamily: 'Montserrat, sans serif',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: 1.5,
      letterSpacing: 'normal',
      textTransform: 'none'
    },
    linkFooter: {
      fontFamily: 'Montserrat, sans serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 1.5,
      letterSpacing: 'normal',
      textTransform: 'none'
    },
    linkMobile: {
      fontFamily: 'Bebas Neue, sans serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 40,
      lineHeight: 1,
      letterSpacing: 'normal',
      textTransform: 'uppercase'
    },
    loader: {
      fontFamily: 'Bebas Neue, sans serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 1,
      letterSpacing: 'normal',
      textTransform: 'uppercase'
    },
    platform: {
      fontFamily: 'Montserrat, sans serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 1,
      letterSpacing: 'normal',
      textTransform: 'none'
    },
    button: {
      fontFamily: 'Bebas Neue, sans serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 18,
      lineHeight: 0.8,
      letterSpacing: 'normal',
      textTransform: 'uppercase'
    },
    subtitle1: undefined,
    subtitle2: undefined,
    body1: undefined,
    body2: undefined,
    caption: undefined,
    overline: undefined
  }
};

const themeOptions: ThemeOptions = {
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: ({ theme }) => ({
          backdropFilter: 'unset !important',
          background: 'unset !important',
          border: 'unset',
          borderImageSlice: '40 250 40 115 fill',
          borderImageSource: 'url(/resources/accordion.png)',
          borderImageWidth: 'auto',
          margin: '16px -8px 16px',
          padding: '8px 0px 8px',
          position: 'relative',

          [`&:before`]: {
            backdropFilter: 'unset !important',
            background: 'unset !important'
          },
          [`&:after`]: {
            content: '""',
            borderImageSlice: '40 250 40 115 fill',
            borderImageSource: 'url(/resources/accordion_hover.png)',
            borderImageWidth: 'auto',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            willChange: 'opacity',
            transition: theme.transitions.create('opacity'),
            height: '100%',
            width: '100%',
            zIndex: -1
          },
          [`&:hover:not(.${accordionClasses.disabled}):after`]: {
            opacity: 1
          },
          [`&.${accordionClasses.expanded}`]: {
            margin: '16px -8px 16px'
          },
          '&:first-of-type': {
            marginTop: '0px'
          },
          '&:last-of-type': {
            marginBottom: '0px'
          }
        })
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.tertiary,
          padding: '0px 28px 28px',
          ...theme.typography.bodyRegular14
        })
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
          minHeight: 'unset',
          minWidth: 'unset',
          padding: '0px 28px 0px',
          ...theme.typography.h5
        }),
        content: {
          margin: '24px 0px 24px',

          [`&.${accordionSummaryClasses.expanded}`]: {
            margin: '24px 0px 24px'
          },
          [`&.${accordionSummaryClasses.focusVisible}`]: {
            background: 'unset'
          }
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true
      },
      styleOverrides: {
        containedPrimary: ({ theme }) => ({
          background: 'linear-gradient(227.61deg, #8F47FA 6%, #0581DB,#0085FF 50%, #0085FF 50%, #31C3F3 70%, #0581DB)',
          backgroundSize: '200% 100%',
          boxShadow: '0px 0px 16px rgba(35, 181, 255, 0.45)',
          color: 'rgba(255, 255, 255, 1)',
          transitionProperty: 'background, box-shadow, border-color, color',

          '&:hover': {
            backgroundPosition: '100%',
            boxShadow: '0px 0px 16px rgba(35, 181, 255, 0.34)',
            color: 'rgba(255, 255, 255, 1)',
            '@media (hover: none)': {
              backgroundPosition: '100%',
              boxShadow: '0px 0px 16px rgba(35, 181, 255, 0.34)',
              color: 'rgba(255, 255, 255, 1)'
            }
          },
          '&:active': {
            backgroundPosition: '100%',
            boxShadow: '0px 0px 16px rgba(35, 181, 255, 0.34)',
            color: 'rgba(255, 255, 255, 1)'
          },
          [`&.${buttonClasses.focusVisible}`]: {
            outline: `${theme.palette.primary.main} solid 1px`
          },
          [`&.${buttonClasses.disabled}`]: {
            color: 'inherit',
            opacity: 0.5
          }
        }),
        containedSizeSmall: ({ theme }) => ({
          padding: '12px 14px',
          height: '40px',
          minWidth: '44px',
          ...theme.typography.button
        }),
        containedSizeMedium: ({ theme }) => ({
          padding: '14px 14px',
          height: '44px',
          minWidth: '48px',
          ...theme.typography.button
        }),
        containedSizeLarge: ({ theme }) => ({
          padding: '16px 14px',
          height: '48px',
          minWidth: '52px',
          ...theme.typography.button
        }),
        outlinedPrimary: ({ theme }) => ({
          backdropFilter: 'blur(9px)',
          background: 'rgba(255, 255, 255, 0.07)',
          border: '1px solid rgba(255, 255, 255, 0.14)',
          color: 'rgba(255, 255, 255, 0.8)',
          transitionProperty: 'backdropFilter, background, box-shadow, border-color, color',

          '&:hover': {
            backdropFilter: 'blur(10px)',
            background: 'rgba(255, 255, 255, 0.1)',
            border: '1px solid rgba(255, 255, 255, 0.5)',
            color: 'rgba(255, 255, 255, 1)',
            '@media (hover: none)': {
              backdropFilter: 'blur(10px)',
              background: 'rgba(255, 255, 255, 0.1)',
              border: '1px solid rgba(255, 255, 255, 0.5) ',
              color: 'rgba(255, 255, 255, 1)'
            }
          },
          '&:active': {
            backdropFilter: 'blur(10px)',
            background: 'rgba(255, 255, 255, 0.1)',
            border: '1px solid rgba(255, 255, 255, 0.5)',
            color: 'rgba(255, 255, 255, 1)'
          },
          [`&.${buttonClasses.focusVisible}`]: {
            outline: `${theme.palette.primary.main} solid 1px`
          },
          [`&.${buttonClasses.disabled}`]: {
            background: 'rgba(255, 255, 255, 0.02)',
            border: '1px solid rgba(255, 255, 255, 0.09)',
            color: 'rgba(255, 255, 255, 0.3)'
          }
        }),
        outlinedSizeSmall: ({ theme }) => ({
          padding: '11px 13px',
          height: '40px',
          minWidth: '44px',
          ...theme.typography.button
        }),
        outlinedSizeMedium: ({ theme }) => ({
          padding: '13px 13px',
          height: '44px',
          minWidth: '48px',
          ...theme.typography.button
        }),
        outlinedSizeLarge: ({ theme }) => ({
          padding: '15px 13px',
          height: '48px',
          minWidth: '52px',
          ...theme.typography.button
        })
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box'
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          height: '100%',
          width: '100%'
        },
        'html, body': {
          scrollBehavior: 'smooth'
        },
        img: {
          pointerEvents: 'none'
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: 'rgba(255, 255, 255, 0.15)'
        },
        light: {
          borderColor: 'rgba(255, 255, 255, 0.12)'
        }
      }
    },
    MuiFormControlLabel: {
      defaultProps: {
        disableTypography: true
      },
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.typography.bodyRegular14
        })
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.typography.subtitleRegular12
        })
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.typography.button
        })
      }
    },
    MuiIconButton: {
      styleOverrides: {
        edgeStart: {
          marginLeft: '-5px',
          marginRight: '-5px'
        },
        edgeEnd: {
          marginLeft: '-5px',
          marginRight: '-5px'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: ({ theme }) => ({
          ...theme.typography.button
        })
      }
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          [`& .${gridClasses.item}`]: {
            maxWidth: '100% !important'
          }
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          [`&:hover, &.${listItemButtonClasses.selected}`]: {
            backgroundColor: 'rgba(255, 255, 255, 0.1) !important'
          }
        }
      }
    },
    MuiListItemText: {
      defaultProps: {
        primaryTypographyProps: {
          color: 'text.primary',
          variant: 'bodyRegular14'
        },
        secondaryTypographyProps: {
          color: 'text.tertiary',
          variant: 'subtitleRegular12'
        }
      }
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          position: 'relative',
          backgroundColor: 'unset'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: ({ theme }) => ({
          opacity: 0.7,
          willChange: 'opacity',
          transition: theme.transitions.create('opacity'),

          '&:hover': {
            opacity: 1,
            '@media (hover: none)': {
              opacity: 1
            }
          },
          '&:active': {
            opacity: 1
          },
          [`&.${linkClasses.focusVisible}`]: {
            borderRadius: '4px',
            outline: `${theme.palette.primary.main} solid 1px`
          }
        })
      },
      variants: [
        {
          props: { variant: 'linkHeader' },
          style: ({ theme }) => ({
            display: 'block',
            opacity: 0.8,
            padding: '4px 8px',
            willChange: 'opacity',
            transition: theme.transitions.create('opacity'),

            '&:hover': {
              borderRadius: '4px',
              opacity: 1,
              outline: `0.5px solid rgba(255, 255, 255, 0.4)`,
              '@media (hover: none)': {
                borderRadius: '4px',
                opacity: 1,
                outline: `0.5px solid rgba(255, 255, 255, 0.4)`
              }
            },
            '&:active, &[active*="true"]': {
              borderRadius: '4px',
              opacity: 1,
              outline: `0.5px solid rgba(255, 255, 255, 0.4)`
            },
            [`&.${linkClasses.focusVisible}`]: {
              borderRadius: '4px',
              outline: `${theme.palette.primary.main} solid 1px`
            }
          })
        },
        {
          props: { variant: 'linkFooter' },
          style: ({ theme }) => ({
            display: 'block',
            opacity: 0.8,
            padding: '0px 4px',
            willChange: 'opacity',
            transition: theme.transitions.create('opacity'),

            '&:hover': {
              opacity: 1,
              '@media (hover: none)': {
                opacity: 1
              }
            },
            '&:active': {
              opacity: 1
            },
            [`&.${linkClasses.focusVisible}`]: {
              borderRadius: '4px',
              outline: `${theme.palette.primary.main} solid 1px`
            }
          })
        },
        {
          props: { variant: 'linkMobile' },
          style: ({ theme }) => ({
            display: 'block',
            opacity: 0.8,
            padding: '10px 16px',
            willChange: 'opacity',
            transition: theme.transitions.create('opacity'),

            '&:hover': {
              borderRadius: '4px',
              opacity: 1,
              outline: `0.5px solid rgba(255, 255, 255, 0.4)`,
              '@media (hover: none)': {
                borderRadius: '4px',
                opacity: 1,
                outline: `0.5px solid rgba(255, 255, 255, 0.4)`
              }
            },
            '&:active, &[active*="true"]': {
              borderRadius: '4px',
              opacity: 1,
              outline: `0.5px solid rgba(255, 255, 255, 0.4)`
            },
            [`&.${linkClasses.focusVisible}`]: {
              borderRadius: '4px',
              outline: `${theme.palette.primary.main} solid 1px`
            }
          })
        }
      ]
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.typography.button,

          [`&:hover, &.${listItemButtonClasses.selected}`]: {
            backgroundColor: 'rgba(255, 255, 255, 0.1) !important'
          }
        })
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          backdropFilter: 'blur(9px)',
          background: 'rgba(255, 255, 255, 0.07)',
          color: 'rgba(255, 255, 255, 0.8)',
          minHeight: '44px',
          maxHeight: '44px',
          padding: '0',
          willChange: ['backdrop-filter', 'background', 'color'],
          transition: theme.transitions.create(['backdrop-filter', 'background', 'color'], {
            duration: theme.transitions.duration.shorter
          }),

          [`&:hover`]: {
            backdropFilter: 'blur(10px)',
            background: 'rgba(255, 255, 255, 0.1)',
            color: 'rgba(255, 255, 255, 1)',
            '@media (hover: none)': {
              backdropFilter: 'blur(10px)',
              background: 'rgba(255, 255, 255, 0.1)',
              color: 'rgba(255, 255, 255, 1)'
            }
          },
          [`&:hover:not(.${outlinedInputClasses.disabled}):not(.${outlinedInputClasses.error}):not(.${outlinedInputClasses.focused}) .${outlinedInputClasses.notchedOutline}`]:
            {
              borderColor: 'rgba(255, 255, 255, 0.5)',
              borderStyle: 'solid',
              borderWidth: '1px',
              '@media (hover: none)': {
                borderColor: 'rgba(255, 255, 255, 0.5)',
                borderStyle: 'solid',
                borderWidth: '1px'
              }
            },
          [`&.${outlinedInputClasses.disabled}`]: {
            background: 'rgba(255, 255, 255, 0.02)',
            color: 'rgba(255, 255, 255, 0.3)'
          },
          [`&.${outlinedInputClasses.disabled} .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: 'rgba(255, 255, 255, 0.09)',
            borderStyle: 'solid',
            borderWidth: '1px'
          },
          '& svg': {
            color: 'rgba(255, 255, 255, 0.8)',
            willChange: 'color',
            transition: theme.transitions.create('color', {
              duration: theme.transitions.duration.shorter
            })
          },
          '&:hover svg': {
            color: 'rgba(255, 255, 255, 1)'
          }
        }),
        notchedOutline: ({ theme }) => ({
          borderColor: 'rgba(255, 255, 255, 0.14)',
          borderStyle: 'solid',
          borderWidth: '1px',
          willChange: 'border-color',
          transition: theme.transitions.create('border-color', {
            duration: theme.transitions.duration.shorter
          })
        }),
        input: {
          padding: '8px 14px',

          [`&.${outlinedInputClasses.disabled}`]: {
            WebkitTextFillColor: 'rgba(255, 255, 255, 0.3)'
          }
        },
        adornedStart: {
          padding: '8px 14px'
        },
        inputAdornedStart: {
          paddingLeft: '0px',
          paddingRight: '0px'
        },
        adornedEnd: {
          padding: '8px 14px'
        },
        inputAdornedEnd: {
          paddingLeft: '0px',
          paddingRight: '0px'
        },
        multiline: {
          maxHeight: 'unset'
        },
        inputMultiline: {
          padding: '8px 14px 4px 14px',

          [`&.${outlinedInputClasses.disabled}`]: {
            WebkitTextFillColor: 'rgba(255, 255, 255, 0.3)'
          }
        },
        sizeSmall: {
          minHeight: '40px',
          maxHeight: '40px'
        },
        inputSizeSmall: {
          padding: '6px 14px',

          [`&.${outlinedInputClasses.disabled}`]: {
            WebkitTextFillColor: 'rgba(255, 255, 255, 0.3)'
          }
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        variant: 'outlined'
      },
      styleOverrides: {
        root: {
          backdropFilter: 'blur(9px)',
          background: 'rgba(255, 255, 255, 0.07)'
        },
        outlined: {
          border: '1px solid rgba(255, 255, 255, 0.14)'
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        displayEmpty: true,
        IconComponent: ExpandMore,
        MenuProps: {
          MenuListProps: {
            disablePadding: true,
            sx: {
              '& li': {
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                py: 1.5,
                px: 2,
                minHeight: 'unset'
              },
              [`& li.${menuItemClasses.disabled}`]: {
                display: 'none'
              }
            }
          },
          sx: {
            mt: 0.5
          }
        }
      },
      styleOverrides: {
        select: ({ ownerState }) => ({
          alignItems: 'center',
          color: ownerState.value === '' ? 'rgba(255, 255, 255, 0.4)' : undefined,
          display: 'flex',
          justifyContent: 'flex-start'
        })
      }
    },
    MuiToolbar: {
      styleOverrides: {
        gutters: ({ theme }) => ({
          [theme.breakpoints.down('lg')]: {
            padding: '20px 16px 20px 16px'
          },
          [theme.breakpoints.up('lg')]: {
            padding: '16px 56px 16px 56px'
          }
        }),
        dense: {
          minHeight: '72px'
        },
        regular: ({ theme }) => ({
          [theme.breakpoints.down('lg')]: {
            minHeight: '88px'
          },
          [theme.breakpoints.up('lg')]: {
            minHeight: '92px'
          }
        })
      }
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          backdropFilter: 'blur(9px)',
          color: 'rgba(255, 255, 255, 0.07)'
        },
        tooltip: ({ theme }) => ({
          backdropFilter: 'blur(9px)',
          background: 'rgba(255, 255, 255, 0.07)',
          ...theme.typography.subtitleMedium10
        })
      }
    },
    MuiTextField: {
      defaultProps: {
        hiddenLabel: true
      }
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h7: 'p',
          h8: 'p',
          subtitleSemibold16: 'p',
          subtitleSemibold14: 'p',
          subtitleMedium12: 'p',
          subtitleMedium10: 'p',
          subtitleRegular12: 'p',
          subtitleRegular10: 'p',
          subtitleRegular8: 'p',
          bodyRegular16: 'p',
          bodyRegular14: 'p',
          bodyRegular8: 'p',
          linkHeader: 'span',
          linkFooter: 'span'
        }
      }
    }
  },
  palette: {
    mode: 'dark',
    background: {
      default: '#00080A',
      paper: '#FFFFFF12'
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#FFFFFFCC',
      tertiary: '#FFFFFF99',
      disabled: '#FFFFFF66'
    },
    primary: {
      main: '#0EAAD5'
    },
    secondary: {
      main: '#FFFFFF66'
    },
    error: {
      main: '#E60000',
      light: '#FF0000'
    },
    warning: {
      main: '#FC7046'
    },
    info: {
      main: '#2641CC'
    },
    success: {
      main: '#34892F',
      light: '#69FF47'
    }
  },
  shadows: Array(25).fill('none') as Shadows,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
    fab: 1050,
    mobileStepper: 1000,
    modal: 1300,
    snackbar: 1400,
    speedDial: 1050,
    tooltip: 1500
  }
};

export const createCustomTheme = (): Theme => {
  let theme = createTheme(merge({}, baseOptions, themeOptions));

  return responsiveFontSizes(theme, {
    variants: [
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'h7',
      'h8',
      'subtitleSemibold16',
      'subtitleSemibold14',
      'subtitleMedium12',
      'subtitleMedium10',
      'subtitleRegular12',
      'subtitleRegular10',
      'subtitleRegular8',
      'bodyRegular16',
      'bodyRegular14',
      'bodyRegular8',
      'linkHeader',
      'linkFooter',
      'linkMobile',
      'loader',
      'platform',
      'button'
    ]
  });
};
