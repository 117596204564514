import React from 'react';

// Externals
import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material';

const Water: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    color="primary"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <path
      d="M406.043 316c24.11 96.443-50.59 180-150 180s-174.405-82.38-150-180c15-60 90-150 150-300c60 150 135 240 150 300z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default Water;
